.square-pants {
  border-radius: 0;
  font-size: x-small;
  margin-top: 10px;
}

.download-tag {
  color: white !important;
  background-color: #0d62fe !important;
  font-weight: 500;
  cursor: pointer;
}
