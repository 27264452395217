.no-decoration-enforce {
  text-decoration: none !important;
}

.no-decoration-enforce:hover {
  text-decoration: none !important;
}

.resource-tile {
  background-color: #f4f8ff;
}

.resource-tile:hover {
  color: white !important;
  background-color: #005dff;
}
