.title {
  font-size: 4rem;
  font-weight: 100;
}

.call-button {
  margin-right: 10px;
  width: 175px !important;
  max-width: 175px !important;
}

.layout-child.meter-title {
  display: none;
}

.layout-child.spacer.cds--cc--spacer {
  display: none;
}

.text-blue {
  color: #005dff;
}

.disclaimer {
  margin-top: 20px;
  font-size: small;
}

.sub-title {
  font-size: 3rem;
  font-weight: 100;
}

.vertial-align-middle {
  vertical-align: middle;
}

.toolbox {
  padding-top: 20px;
  border: #005dff solid 0.5px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.read-more {
  margin-top: 10px;
  margin-right: 10px;
  background-color: #eaeaea;
  text-decoration: none;
}

.read-more:hover {
  text-decoration: none;
}

.disabled {
  cursor: not-allowed;
}
