.tool {
  min-height: 175px;
  position: relative;
}

.tool-links {
  position: absolute;
  bottom: 0;
}

.button-diminish {
  background-color: #eee;
  color: black;
  width: 50px !important;
  margin-bottom: 15px;
  margin-right: 10px;
  box-shadow: none !important;
  width: 2.5rem;
  min-height: 1rem;
}
