.poster {
  min-height: 300px;
  position: relative;
}

.poster-links {
  position: absolute;
  bottom: 0;
}

.poster-tag {
  margin: 0;
  margin-right: 5px;
  border-radius: 0px;
}
