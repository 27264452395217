.disabled-hover > a:hover {
  cursor: not-allowed !important;
}

.page-numbers {
  color: red;
}

.pink-diminish > a {
  background-color: #d02771;
  color: white;
}

.blue-diminish > a {
  background-color: #1363fe;
  color: white;
}
.green-diminish > a {
  background-color: #24a147;
  color: white;
}
